.box-200{
    padding: 10px;
    background: #FFFFFF;
    width: 275px;
      border: 2px solid #E5E5E5;
    border-radius: 4px;
}
.company-logos{
    width: 80px;
    height: 80px;
    border-radius: 50%;
  border: 5px solid rgb(247, 243, 243);
    background: #FFFFFF;
    object-fit: contain;
}

.black-border{
    width: 90%;
    margin: auto;
    border-top: 1px solid gray;
    opacity: 0.4;
   
}
.rem-link-def{
    text-decoration: none;
    color: black;
}
.rem-link-def:hover{
    color: rgb(13, 32, 13);
   
}
