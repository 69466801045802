$dark-green: #213d30;
$light-green: #74b496;
$card-background-color: #f3f3f3;
$profile-border-color: #dde1df;
$light-text-color: #6f6f6f;
$text-black: #000000;
$border-bottom-color: #eeeeee;
$btn-grey-background: #c4c4c4;
$light-bg-color: #d5e8e0;
@font-face {
  font-family: "Helvetica";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

body {
  font-family: "Helvetica";
}

.label-required {
  color: #f46a6a;
}

// .form-group label{
//     color:$light-text-color;
// }
// .form-group input,.form-group select{
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #E0E0E0;
//     padding: 0px 0px;
//     height: auto;
// }
.text-color-light {
  color: $light-text-color;
}

.form-group input::placeholder {
  color: $light-text-color;
  font-weight: 300;
}

.font-weight-200 {
  font-weight: 200;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

//button background color
.bg-green {
  background-color: #213d30;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.bg-light-green {
  background-color: #74b496;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.bg-grey {
  background-color: $btn-grey-background;
  color: $text-black;
  &:hover {
    color: $text-black;
  }
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-outline-shadow {
  color: #213d30;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #fff;
  &:hover {
    color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  &:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}

.btn-outline-green {
  color: #213d30;
  border-color: #213d30;
  &:hover {
    color: #fff;
    background-color: #213d30;
    border-color: #213d30;
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-light-green {
  color: #74b496;
  border-color: #74b496;
  &:hover {
    color: #fff;
    background-color: #74b496;
    border-color: #74b496;
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-grey {
  color: $text-black;
  border-color: #4f4f4f;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

//text color
.green-text-color {
  color: #213d30;
  &:hover {
    color: #213d30;
  }
}

.light-green-text {
  color: $light-green;
  &:hover {
    color: $light-green;
  }
}

.text_light_green {
  color: #34c38f;
}

.text_light_green:hover {
  color: #248763;
}

.light-green-text-color {
  color: #447e63;
  &:hover {
    color: #447e63;
  }
}

//Date Css
.cust-date-icon {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 18px;
  cursor: pointer;
}

.react-datepicker__input-container input::placeholder {
  color: #e0e0e0;
}

//Toast Notification
#toast-container > .toast-error {
  background-image: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

///Body
body[data-layout-size="boxed"] {
  background-color: #f6f6f6;
  color: $dark-green;
}

//page-content padding
body[data-layout="horizontal"] {
  .page-content {
    padding-top: #{$grid-gutter-width};
    background-color: $white;
  }
}

//top header
body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  background-color: $dark-green;
}

.app-search {
  width: 55%;
  margin-left: 5em;
  display: grid;
  grid-template-columns: 1fr;
}

body[data-layout="horizontal"][data-topbar="colored"]
  .app-search
  .form-control {
  border-radius: 2px;
  background-color: #fff;
  color: #495057;
  padding-left: 15px;
  padding-right: 40px;
  height: 30px;
}

.app-search span {
  left: unset;
  right: 13px;
}

body[data-layout="horizontal"][data-topbar="light"] a.header-item {
  &:hover,
  &.active {
    color: $dark-green;
    border-bottom: 3px solid $dark-green;
    // font-weight: bold;
    text-shadow: 0 0 0.65px $dark-green, 0 0 0.65px $dark-green;
  }
  padding-bottom: 22px;
}

body[data-layout="horizontal"][data-topbar="light"] .app-search span,
body[data-layout="horizontal"][data-topbar="light"]
  .app-search
  input.form-control::-webkit-input-placeholder {
  color: #74788d;
}

.navbar-header .logo.logo-dark {
  img {
    width: 160px;
  }
}

// React Table Css
.table.react-table {
  & tbody {
    background-color: $card-background-color;
  }
  & th,
  td {
    border: none;
  }
  & td {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    vertical-align: middle;
  }
}

// link underline
.sign-in-link {
  color: $dark-green;
  border-bottom: 1px solid $dark-green;
  &:hover {
    color: $dark-green;
    border-bottom: 1px solid $dark-green;
  }
}

.text-underline-none {
  text-decoration: none;
}

.text-under-line {
  text-decoration-line: underline;
}

body[data-layout-size="boxed"] #layout-wrapper {
  box-shadow: none;
}

.twitter-bs-wizard-tab-content {
  min-height: 160px;
}

.dropdown-menu {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0px 4px 4px;
  a {
    color: $light-green;
  }
}
.hoverChange:hover {
  background-color: #e5e5ee;
}

.select-container .css-1hb7zxy-IndicatorsContainer,
.select-container .css-1hb7zxy-IndicatorsContainer {
  display: block;
}

.select-container .css-tlfecz-indicatorContainer,
.select-container .css-tlfecz-indicatorContainer {
  color: hsl(0, 0%, 80%);
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.3;
}

.job-form .year-text {
  position: absolute;
  right: 11%;
  top: 55%;
}

.job-form,
.job-dashbord,
.application-tab {
  .skill-container {
    min-width: 45px;
    padding: 0px 5px;
    border-radius: 45px;
    border: 1px solid #c4c4c4;
    position: relative;
    color: $white;
    background-color: #74b496;
    font-weight: 300;
    font-size: 14px;
  }
}

.detail-tab {
  .skill-container {
    padding: 5px 10px;
    border-radius: 45px;
    border: 1px solid #c4c4c4;
    position: relative;
    color: $white;
    background-color: #74b496;
    font-weight: 300;
    font-size: 14px;
  }
}

.cut-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* React Tag Styles Start */

div.ReactTags__tags {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  position: static;
}

/* Styles for the input */

div.ReactTags__tagInput {
  width: 100%;
  display: inline-block;
  border: none !important;
}

div.ReactTags__tagInput:focus {
  border: none !important;
  outline: none;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  // border: 1px solid #eee;
  padding: 0 4px;
  border: none !important;
  border-color: transparent;
  outline: none;
}

/* Styles for selected tags */

div.ReactTags__selected span.ReactTags__tag {
  color: $white;
  background-color: #74b496;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 2px 5px;
  cursor: move;
  border-radius: 20px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: $white;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

div.ReactTags__suggestions {
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow: visible;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  max-height: 300px;
  overflow: auto;
  letter-spacing: normal;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e5ee;
  padding-left: 0px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  margin-left: 0px;
  font-family: inherit;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  margin-left: 0px;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
  margin-left: 0px;
}

/* React Tag Styles End */

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 70px !important;
    }
  }
}

.notification_container {
  .dropdown-menu a {
    &:hover {
      color: #74b496;
    }
  }
  .btn:focus {
    box-shadow: none;
  }
}

.web_display {
  display: flex;
}

.btn-primary {
  background-color: $dark-green;
  border-color: $dark-green;
}

.mobileDevice {
  .table-responsive {
    background-color: #fff;
  }
  .mobile_margin_5 {
    margin-top: 2em;
  }
  .navbar-header {
    .logo.logo-dark img {
      width: 115px;
    }
    padding-left: 12px;
    padding-right: 0;
    .navbar-brand-box {
      padding: 0;
    }
    .header-font .btn {
      padding: 0.47rem 0.5rem;
    }
  }
  .header_down_arrow {
    font-size: 1.5em;
  }
  .job-dashbord {
    .job-tabs {
      padding: 10px;
      .nav-link {
        padding: 0.5rem 0.5rem;
      }
      .nav-item {
        margin-bottom: 2px;
      }
    }
    .skill-container {
      max-width: 100px;
    }
  }
  .profile-page {
    margin-top: 90px !important;
    .about-section {
      margin-left: 0.3em;
      margin-right: 0.3em;
    }
    .mobile_padding {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .right-pencil-section {
      position: relative;
      top: 0;
      right: 0;
    }
    .mobile_pencil_icon {
      position: absolute !important;
      right: 45px;
      top: 25%;
    }
    .top-container .company-info {
      min-height: 60px;
      margin-top: -2em;
    }
    .mobile_profile_header {
      margin-bottom: 1rem !important;
    }
  }
  .mobile_top_spacing {
    margin-top: 1em;
  }
  .mobile_no_left_margin {
    margin-left: 0px !important;
  }
  .account-setting {
    margin-top: 90px !important;
    .mobile_padding {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .top-container .company-info {
      height: auto;
      min-height: 60px;
    }
    .mobile_account_header {
      margin-bottom: 1rem !important;
    }
  }
  .job-details {
    .mobile_job_detail_header {
      margin-bottom: 1rem !important;
      padding-right: 0 !important;
    }
  }
  .web_display {
    display: block;
  }
  .mob_display {
    display: flex;
  }
  .application_status_header {
    background-color: #f6f6f6;
  }
  .mobile_jobdetail_status_text {
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
    margin-left: 3rem !important;
  }
  // .twitter-bs-wizard.job-wizard .twitter-bs-wizard-nav{
  //     display: none;
  // }
  .account-pages {
    .right-section {
      display: none;
    }
    .left-section {
      width: 100%;
      padding: 20px 0px;
    }
    .login-heading {
      margin-left: 0;
    }
    .login-form {
      margin-left: 0;
    }
    .form_wrapper {
      padding-left: 0 !important;
    }
  }
  .twitter-bs-wizard
    .twitter-bs-wizard-nav
    .nav-item
    .nav-link
    .step-number::before {
    left: 62%;
    width: 92%;
  }
  .dashboard_mobile_left_padding {
    padding-left: 0px !important;
  }
  .dashboard_mobile_right_padding {
    padding-right: 0px !important;
  }
}

body[data-layout="horizontal"].mobileDevice .page-content.job-assessment {
  margin-top: 0px !important;
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: block;
  }
  .mobileDevice {
    .job-assessment .job-footer {
      display: flex;
      flex-direction: column;
      height: 95px;
      .mobile_btn {
        margin-left: 0.5rem !important;
      }
    }
  }
}

body[data-layout="horizontal"] .page-content {
  background-color: #f6f6f6;
}

.toast-top-right .toast-success {
  border: 2px solid #34c38f !important;
  background-color: #34c38f !important;
}

.font-size-36 {
  font-size: 36px !important;
}

@media screen and (max-width: 767px) {
  .mobileDevice .profile-page .mobile_profile_header {
    padding-left: 15px !important;
  }
  body .profile-page .top-container .ux-designer,
  body .job-details .top-container .ux-designer {
    height: auto;
  }
  .mobile_job_detail_header {
    flex-flow: row wrap;
  }
  body .edit-profile-modal .green-status-text:last-child {
    margin: 0;
  }
}

@media screen and (max-width: 360px) {
  body .edit-profile-modal .green-status-text {
    margin: 0 2px 0 0;
    width: 90px;
    height: 90px;
    flex: 1 0 90px;
    max-width: 85px;
    border-radius: 50px;
    align-content: center;
    align-self: center;
    display: grid;
  }
  .edit-profile-modal .company-size {
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
}

div {
  overflow-wrap: normal;
  white-space: pre-wrap;
}
